@import "../../styles/animations.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_display.scss";

.searchBarContainer {
  background: white;
  margin: 0px 5px 0px 10px;
  // width: 180px !important;

  input,
  div {
    background-color: transparent !important;
    color: $headerSearchIconColor !important;
  }

  input {
    border-color: $headerSearchInputBorderColor !important;
  }

  .searchBarIcon {
    cursor: pointer;
    height: 15px;
    margin-top: 4px;
    width: 15px;

    &:hover {
      @include shakeRotater(1s, 1);
    }
  }
}
