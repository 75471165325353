@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.appMenuContainer {
  @include flexView(column, space-between, flex-start);
  border-right: 1px outset $menuBGColor;
  flex-shrink: 0;
  // height: calc(100% - $headerHeight);
  height: inherit;
  ;
  position: relative;
  transition: width ease-in-out 0.5s;
  width: $menuWidth;
  z-index: 3001;


  &.appMenuContainerCompact {
    width: $menuWidthCompact;
  }

  a {
    text-decoration: unset;
    flex: 1;
  }

  .appMenu {
    background-color: $menuBGColor;
    color: $menuTextColor;
    height: 100%;
    max-width: unset !important;

    >div {
      height: 100%;
    }

    .profileMenuItem {
      align-items: flex-end;
      height: 100%;

      .menuProfileContainer {
        background-color: $menuProfileBGColor;
        border-radius: 50% 0;
      }
    }

    .menuItemContainer {
      @include flexView(row, flex-start, center);
      color: $menuTextColor;
      padding: 5px 10px 5px 10px;
      margin: 5px 5px 5px 5px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      &:hover {
        color: $menuTextHoverColor;
        background-color: $menuBGHoverColor;
        border-radius: 10px;
        font-weight: 600;
      }

      .menuItemText {
        padding-left: 10px;
        white-space: nowrap;
      }
    }

  }

  .appMenuListContainer {
    //   display: flex;
    // flex-direction: column;
    flex: 1;
  }

  .logoutIconContainer {
    @include flexView(column, flex-end, center);
    cursor: pointer;
    padding-bottom: 20px;
    width: 100%;

    .logoutIcon {
      color: $menuTextColor;
    }

    &:hover {
      .logoutIcon {
        color: $menuTextHoverColor;
      }
    }
  }

  .menuItemIcon {
    font-size: $menuIconSize;
  }
}

.appContainerSmall {
  .appMenuContainer {
    position: fixed !important;
    top: $headerHeight !important;
  }
}

.accountMenu {
  width: 180px;//$menuWidth ; 
  position: absolute; 
  top: 50px; 
  margin: 20px; 
  padding: 0 10px;
  display: none;
  border: 1px #888 solid;

  
}
.accountMenuVisible{
  display: block;
}

.bottomLinksContainer{
  font-size: 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  >a{
    margin: 5px;
    flex:1;
  }

}
.bottomLinksContainerCompact{
  display: none;
}