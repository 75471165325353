$footerHeight: 32px;
$headerHeight: 50px;

$recordItemFirstHeight: 15%;
$recordItemIconSize: 40px;
$recordItemFirstWidth: calc(50% - 20px);

$recordItemWidth: 400px;

$pageAddButtonHeight: 40px;
$pageAddButtonWidth: 300px;
$pageAddButtonLeft: calc(50% - ($pageAddButtonWidth / 2));
$pageAddContainerHeight: 0%;//calc($pageAddButtonHeight + 20px);

$recordItemHeight: 120px;
$recordItemImageSize: 40px;
$recordItemMiddleWidth: 120px;
$recordItemIconSize: 28px;

$recordInfoFontSize: 14px;
$recordEditorInputFontSize: 12px;
$selectInputFontSize: 12px;

$menuWidth: 240px;
$menuWidthCompact: 40px;
$menuIconSize: 18px;

@mixin flexView($dir: row, $justifyContent: unset, $alignItems: unset) {
  align-items: $alignItems;
  display: flex;
  flex-direction: $dir;
  justify-content: $justifyContent;
}

@mixin flexCenterView($dir: row) {
  @include flexView($dir, center, center);
}

@mixin disableCopy() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
