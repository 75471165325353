@import "../../styles/display.scss";

.recordFieldInfoContainer.recordFieldInfoFilesContainer {
  overflow: hidden;
  width: 100%;
  flex-direction: column;

  .recordFieldInfoFileContainer {
    @include flexView(row, flex-start, center);
    overflow: hidden;
    width: 100%;

    .recordFieldInfoFileIcon {
      color: #0d6efd;
    }

    .recordFieldInfoFile {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 95%;
    }
  }
}
