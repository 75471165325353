@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.recordEditorContainer {
  @include flexView(column, flex-start, center);

  height: 100%;
  overflow: auto;
  width: 100%;

  .recordEditorRows {
    @include flexView(column, flex-start, center);
    height: 80%;
    max-height: 80%;
    overflow: auto;
    width: 100%;
  }

  .recordEditorRow {
    @include flexView(row, space-between, center);
    margin: 10px 0px;
    width: 90%;

    > div {
      min-width: 100%;
      width: 100%;
    }

    &.recordEditorSaveRow {
      margin-bottom: 0px;
    }

    &.recordEditorTagsContainer {
      @include flexView(row, flex-start, center);
      flex-wrap: wrap;
      margin-top: 0px;
      > div {
        min-width: unset;
        width: unset;
      }
    }

    .recordEditorSaveButton {
      background-color: $editorButtonBGColor;
      width: 100%;
    }
  }
}
