@import "../../styles/_colors.scss";
@import "../../styles/_display.scss";

.pageheader {
  @include flexView(row, flex-start, center);
  background-color: $headerBGColor;
  border-bottom: 1px solid $headerBorderColor;
  box-shadow: 0px 1px 3px $headerBorderColor;
  color: $headerTextColor;
  font-size: 20px;
  
  height: $headerHeight;
  padding: 0px 15px 0 0;
  position: relative;
  width: 100%;
  z-index: 2001;

  .TitleContainer {
    @include flexView(row, flex-start, center);
  //  position: relative;
  
      flex: 1;
      padding-left: 5px;
      align-items: center;
      font-weight: 500;
  }
  .pageHeaderTitle {
    @include flexView(row, flex-start, center);

    transition: width ease-in-out 0.5s;
    width: $menuWidth;
    // padding: 5px;
    // margin-bottom: 10px;
    // border-right: 1px outset #fff;

    background-color: #ffffff;
    border-bottom: 1px solid #7159aa;
    // box-shadow: 0px 1px 3px #7159aa;
    color: #7159aa;
    font-size: 18px;
    height: 50px;
    // padding: 0px 10px 0px 15px;
    margin-left: 0px;

    

    z-index: 2001;




   
    &.pageHeaderTitleSmall {
      // width: 40px;
      // width: $menuWidthCompact;
      width: $menuWidth;
      justify-content: flex-start;
      position: fixed;
      border-right: 1px outset #fff;
      
      
    }

    &.pageHeaderTitleCompact {
      // width: 40px;
      width: $menuWidthCompact;
      justify-content: center;
      padding: 0;
      // padding: 0;
      span {
        display: none;
        
      }
    }

    img {
      height: 32px;
    }
  

    span {
      color: $headerTextColor;
      font-family: Loftygoals;
      font-size: 18px;
      white-space: nowrap;
      padding-left: 10px;;
      
    }
    
  }

  .pageHeaderSpace {
    @include flexCenterView(row);
    // flex-grow: 1;
    // width:100%;
    flex:1;
    // padding-left: 15px;
    margin-left: 15px;
    &.pageHeaderSpaceSmall {
      // padding-left: $menuWidth ;
    }

    &.pageHeaderSpaceCompact {
      padding-left: $menuWidthCompact;
    }
  }

  .pageHeaderMainInfo {
    @include flexCenterView(column);
    flex-grow: 1;
    font-size: 18px;
    position: absolute;
    width: 80%;
  }
}
.menuIcon{
  margin:-10px;
  padding-left: 0px;
}
.logoIcon{
  // transition: display ease-in-out 0.5s;
  opacity: 100%;
  width:32px;
  // display: block;
  // transform:translate(0px);
  transition: opacity 0.2s, width 0.5s;
  &.logoIconCompact {
    opacity: 0%;
    width:1px;
    // display: none;
    // transform:translate(9999px);
  }
}

