@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.fileUploadRow {
  @include flexCenterView(row);

  .fileUploadText {
    min-width: 76%;
    width: 76%;
  }

  .fileUploadButton {
    background-color: $recordEditorUploadBGColor;
    font-size: 10px;
    min-width: 20%;
    width: 20%;
  }
}
