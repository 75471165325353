@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.promptModal {
  @include flexCenterView(column);
  width: 300px !important;

  .promptModalMessage {
    @include flexCenterView(row);
    font-weight: 700;
    padding: 0px 0px 10px 0px;
  }

  .promptModalButton {
    @include flexCenterView(row);

    .promptModalOk,
    .promptModalCancel {
      background-color: $editorButtonBGColor;
      font-size: 12px;
      margin: 0px 10px;
      width: 100%;
    }
  }
}
