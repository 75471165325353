@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.recordItemContainer {
  @include flexCenterView(column);
 
  position: relative;
  width: 80%;
  z-index: 2;

  
  .recordItemDataContainer {
    @include flexCenterView(column);
    width:100%;
    background-color: $recordItemBGColor;
    border: 1px solid $recordItemBorderColor;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    // border-radius: 10px;
    // height: $recordItemHeight;
    min-height: $recordItemHeight;
    &:hover {
      background-color: $recordItemHoverBGColor;
  
      .recordItemOperations {
        visibility: visible;
      }
    }
  }
  .recordItemDateContainer {
    @include flexView(row);
    align-self: flex-start;
    // background-color: $recordItemDateBGColor;
    // border: 1px solid $recordItemDateBorderColor;
    // border-bottom-left-radius: 10px;
    // border-bottom-width: 0px;
    // border-top-right-radius: 10px;
    // border-width: 0px 0px 1px 1px;
    color: $recordItemDateTextColor;
    font-size: 12px;
    padding: 2px 10px;
    right: 0px;
    top: 0px;
    // width: $recordItemMiddleWidth;
  }
  .recordItemTitleContainer {
      @include flexView(row, space-between, center);
      width: 100%;

 
      border-radius: 0px 0px 10px 10px;
      border-top-width: 0px;
      color: $recordItemTitleTextColor;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 10px 5px 20px;

      .recordItemTitleLabel {
        border-radius: 0px 0px 0px 10px;
        margin-inline-end: 5px;
      }

      .recordItemIconContainer {
        @include flexCenterView(column);
        border-width: 0px;
        height: $recordItemIconSize;
        margin-inline-end: 5px;
        width: $recordItemIconSize;

        img {
          height: 100%;
          /* width: 100%; */
        }
      }
      .recordItemTitleInfo {
        border-radius: 0px 0px 10px 0px;
        color: $recordItemTitleTextColor;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    
  }



  .recordItemOperations {
    @include flexCenterView(row);
    padding-inline-start: 10px;
    visibility: hidden;

    .recordItemEditButton,
    .recordItemRemoveButton {
      cursor: pointer;
      height: 15px;
      margin: 0px 2px;
      opacity: 0.8;
      width: 15px;
    }

    .recordItemEditButton {
      color: $recordItemOperationEditTextColor;
    }

    .recordItemRemoveButton {
      color: $recordItemOperationRemoveTextColor;
    }
  }
  
  .recordItemInfo {
    @include flexCenterView(row);
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
    

    .recordItemInfoStart {
      flex-grow: 1;
      height: 100%;
      max-width: 100%;
      overflow: auto;
      width: 100%;
    }

    .recordItemInfoStart {
      @include flexView(column, flex-start, center);
    }

    .recordItemTagsContainer {
      @include flexView(row, flex-end, center);
      flex-wrap: wrap;
      padding: 10px;
      width: 100%;

      .recordItemTag {
        @include flexCenterView(column);
        background-color: $recordItemTagBGColor;
        border: 1px solid $recordItemTagBorderColor;

        border-radius: 4px;
        color: $recordItemTagTextColor;
        font-size: 12px;
        margin: 2px;
        padding: 2px 6px 2px 6px;
        position: relative;
      }
    }
    .recordItemFields {
      @include flexView(column, flex-start, flex-start);
      // padding-inline-end: 10px;
      width: 90%;
    }
  }

  .recordItemInfoSmall {
    flex-direction: column;
  }




  .recordItemStartImageContainer,
  .recordItemEndImageContainer {
    height: $recordItemImageSize;
    opacity: 0;
    position: absolute;
    top: calc($recordItemHeight / 2 - $recordItemImageSize / 2);
    width: $recordItemImageSize;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .recordItemStartImageContainer {
    left: 20px;
  }

  .recordItemEndImageContainer {
    right: 20px;
  }
}


.appContainerSmall {
  .recordItemTitle {
    font-size: 12px !important;
    max-width: calc(100% - $recordItemMiddleWidth - 10px);
  }

  .recordItemTag {
    font-size: 10px !important;
  }
}
