@import "../../styles/display.scss";

.recordFieldInfoSymptoms {
  @include flexView(row, flex-start, baseline);

  flex-wrap: wrap;
  width: 100%;

  span {
    padding: 0px 5px;
  }
}
