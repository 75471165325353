@import "../../styles/colors.scss";
@import "../../styles/display.scss";

/* Google Login Button */
.googleLoginContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: #fff; // Google's white button
  color: #000; // Black text
  border: 1px solid #000;
  &:hover {
    background-color: #f1f1f1;
  }
}

.googleLoginIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Set fixed width for the container */
  height: 32px; /* Set fixed height for the container */
  img {
    max-width: 100%; /* Ensure the image scales properly */
    max-height: 100%;
    object-fit: contain; /* Maintain aspect ratio */
    display: block; /* Prevent inline image layout issues */
  }
}

.googleLoginText {
  font-size: 16px;
}

/* Apple Login Button */
.appleLoginContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: #000; // Apple's black button
  color: #fff; // White text
  &:hover {
    background-color: #333;
  }
}

.appleLoginIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Set fixed width for the container */
  height: 32px; /* Set fixed height for the container */
  img {
    max-width: 100%; /* Ensure the image scales properly */
    max-height: 100%;
    object-fit: contain; /* Maintain aspect ratio */
    display: block; /* Prevent inline image layout issues */
  }
}

.appleLoginText {
  font-size: 16px;
}
