@import "../../styles/display.scss";

.recordFieldInfoBloodExam {
  @include flexView(column, flex-start, flex-start);

  // flex-wrap: wrap;
  width: 100%;

  > div {
    @include flexView(row, center, center);
    margin: 0px;
    padding-bottom: 0px;
    width: 100%;
    flex:3;
  }

  > :nth-child(even) {
    justify-content: flex-end;
  }
}
