@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.recordFieldInfoContainer {
  border: 0px solid $recordInfoFieldBorderColor;
  border-radius: 8px;
  direction: ltr;
  font-size: $recordInfoFontSize;
  margin: 2px 2px 0px 0px;
  padding: 3px 5px;
  width:100%;
  display:flex;

  .recordFieldInfoLabel {
    font-size: 12px;
    font-weight: 400;
    // padding-top: 2px;
    justify-content: flex-end;
    display: flex;
    // align-items: center;
    // flex:1;
    width:100px;
  }

  .recordFieldInfoValue {
    font-weight: 600;
    padding-left: 10px;
    flex:1;
    // flex-grow: inherit;
    display: flex;
    align-items: center;
  }
}

.appContainerSmall {
  .recordFieldInfoLabel {
    font-size: 10px !important;
  }
  .recordFieldInfoValue {
    font-size: 12px !important;
  }
}
