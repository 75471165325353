@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.personEditorContainer {
  @include flexView(column, flex-start, center);

  height: 100%;
  width: 100%;

  .personAvatarContainer {
    @include flexCenterView(column);
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    height: 70px;
    margin: 10px 0px;
    position: relative;
    width: 70px;

    .personAvatar {
      background-color: $avatarBGColor;
      border: 1px solid $avatarBorderColor;
      border-radius: 50%;
      cursor: pointer;
      height: 100%;
      width: 100%;
    }

    .personAvatarIcon {
      color: $avatarBorderColor;
      cursor: pointer;
      height: 100%;
      width: 100%;
    }

    .personAvatarRemove {
      color: $avatarBorderColor;
      cursor: pointer;
      position: absolute;
      right: -10px;
      top: -2px;
    }
  }

  .personEditorRows {
    @include flexView(column, flex-start, center);
    height: 100%;
    max-height: 100%;
    overflow: auto;
    width: 100%;
  }

  .personEditorRow {
    @include flexView(row, space-between, center);
    margin: 10px 0px;
    width: 90%;

    > div {
      min-width: 100%;
      width: 100%;
    }

    &.personEditorSaveRow {
      margin-bottom: 0px;
    }
  }

  .personEditorSaveButton {
    background-color: $editorButtonBGColor;
    width: 100%;
  }
}
