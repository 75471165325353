@import "../../styles/colors.scss";
@import "../../styles/display.scss";
@import "../../styles/sizes.scss";

 .downloadContainer {
    @include flexView(column, flex-start, center);
    margin-top: 25px;
    height: 100%;
    position: relative;
    width: 100%;
    .storesWidgetContainer{
        @include flexView(row, flex-start, center);
        margin-top: 50px;
        width:70%;
        height: auto;

        .storeType{
            width: 400px;
            text-align: center;
            font-weight: 500;
        }
        .app-stores{
            @include flexView(column, center, center);
            img {
                height: 64px;
                cursor: pointer;
                transition: transform 0.2s ease;          
              }
            img:hover {
                transform: scale(1.05);
            }           
        } 
    }

    .storesWidgetContainerSmall{
        @include flexView(column, center , center);
        margin-top: 32px;
        width:100%;
    
        height: auto;
        .storeType{
            width: 200px;
            // align-self: flex-end;
            font-weight: 500;
        }
    }
}
