.recordEditorContainer .recordEditorRow {
  .recordEditorPrescription {
    min-width: 90%;
    width: 90%;
  }

  .recordEditorPrescriptionDose,
  .recordEditorPrescriptionOrder {
    min-width: 44%;
    width: 44%;
  }

  .recordEditorPrescriptionPlus,
  .recordEditorPrescriptionMinus {
    font-size: 25px;
    min-width: 10%;
    width: 10%;
  }
}
