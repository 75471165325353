@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.personItemContainer {
  @include flexView(row, space-between);
  
  
  margin-top: 10px;
  
  width: 90%;
  


  

  .personItemInfoContainer {
    @include flexView(row, flex-start, center);
    // width: 80%;
    flex:1;
    height: 120px;
    cursor: pointer;
    border: 1px solid $personItemBorderColor;
    padding: 10px 20px 10px 30px;
    border-radius: 10px;
    &:hover {
      background-color: $personItemHoverBGColor;
  
      // .personItemOperations {
      //   display: flex;
      // }
    }
    .personItemAvatar {
      height: 50px;
      margin-right: 20px;
      width: 50px;

      img {
        border: 1px solid $avatarBorderColor;
        border-radius: 50%;
        height: 100%;
        padding: 1px;
        width: 100%;
      }

      .personItemAvatarIcon {
        color: $avatarIconColor;
        height: 50px;
        width: 50px;
      }
    }

    .personItemTitleContainer {
      flex-grow: 1;
      margin-bottom: 5px;

      .personItemNameContainer {
        .personItemName {
          color: $personItemTextColor;
          font-size: 18px;
          font-weight: 700;
        }

        .personItemGenderIcon {
          color: #20b2aa;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 3px;
          margin-inline-start: 5px;
        }
      }
    }

    .personItemBirth {
      @include flexView(row, flex-start, center);
      font-size: 12px;

      .personItemBirthLabel {
        font-weight: 700;
      }

      .personItemBirthValue {
        margin-inline-start: 5px;
      }
    }
  }

  .personItemBloodInfo {
    @include flexCenterView(row);
    border-radius: 8px;
    color: #e8573f;
    font-size: 10px;
    margin: 5px 5px 5px 0px;
    min-width: fit-content;
    padding: 3px 5px;

    .personItemBloodIcon {
      height: 20px;
      width: 20px;
    }

    .personItemInfoLabel {
      font-weight: 700;
    }

    .personItemInfoValue {
      font-size: 14px;
      font-weight: 600;
      padding-left: 5px;
    }
  }

  .personItemOperations {
    @include flexView(column, space-evenly, center);
    color: #888787;
    display: flex;
    padding: 20px 10px;

    .personItemEditButton,
    .personItemRemoveButton {
      cursor: pointer;
      margin: 0px 2px;
      opacity: 0.8;
    }

    .personItemEditButton {
      color: #20b2aa;
    }

    .personItemRemoveButton {
      color: #f88181;
    }
  }
}

.appContainerSmall {
  .personItemName {
    font-size: 14px !important;
  }

  .personItemBirth {
    font-size: 10px !important;

    .personItemBirthLabel {
      font-weight: 700 !important;
    }

    .personItemBirthValue {
      margin-inline-start: 5px !important;
    }
  }
}
