@import "../../styles/display.scss";

.datePickerInput {
  width: 100%;

  .MuiInputBase-input {
    font-size: $recordEditorInputFontSize;
    height: 10px;
  }
}

.MuiModal-root.MuiDialog-root {
  z-index: 5001;
}

.MuiPickersToolbar-root.MuiPickersLayout-toolbar {
  > span:first-child {
    display: none;
  }
}
