@import "../../styles/colors.scss";
@import "../../styles/display.scss";
@import "../../styles/sizes.scss";

.personPageContainer {
  @include flexView(column, flex-start, center);

  height: 100%;
  position: relative;
  width: 100%;

  .editPersonModal {
    max-height: 90%;
    width: 300px;
  }

  .personsContainer {
    @include flexView(column, flex-start, center);
    height: 100%;
    overflow: auto;
    padding: 10px 0px;
    width: 100%;
  }

  .personAddContainer {
    @include flexCenterView(column-reverse);
    bottom: 0px;
    height: $pageAddContainerHeight;
    position: fixed;
    width: 90%;
    z-index: 2001;

    .personAddButtonContainer {
      @include flexCenterView(column-reverse);
      align-self: center;
      border-radius: 3px;
      cursor: pointer;
      font-size: 20px;
      left: $pageAddButtonLeft;
      min-width: $pageAddButtonWidth;
      width: $pageAddButtonWidth;
      z-index: 2;

      .personAddButton {
        background-color: $addButtonBGColor;
        font-size: 14px;
        height: $pageAddButtonHeight;
        width: 100%;

        &.personAddTypesView {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
  }
}

.appContainerSmall {
  .personsContainer,
  .personAddContainer {
    margin-left: $menuWidthCompact;
  }

  .personsContainer {
    width: calc(100% - $menuWidthCompact);
  }
}
