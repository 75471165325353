@import "../../styles/colors.scss";
@import "../../styles/display.scss";



.loginPageContainer {
  @include flexView(column, flex-start, center);
  background: $loginBackgroundGradient;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  


  .verifyContainer {
    @include flexView(column, space-between, center);
    // height: 80%;
    padding: 30px 30px;
    margin: 20px;
    // width: 500px;
    border: 1px solid hsl(var(--white));
    box-shadow: 0px 20px 80px hsla(var(--baseColor2), 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    background-image: radial-gradient(circle at 50% 0, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2) 20%);
    border-radius: 25px;
    
    z-index: 2;

  }
}
