@import "../../styles/display.scss";

.autoCompleteView {
  input,
  textarea,
  label,
  li {
    font-size: $recordEditorInputFontSize;
  }

  &.Mui-focused {
    label {
      font-size: unset;
    }
  }
}

.MuiAutocomplete-option {
  font-size: $recordEditorInputFontSize;
}
