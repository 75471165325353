@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?oablrh');
  src:  url('fonts/icomoon.eot?oablrh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?oablrh') format('truetype'),
    url('fonts/icomoon.woff?oablrh') format('woff'),
    url('fonts/icomoon.svg?oablrh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter:before {
  content: "\e913";
}
.icon-link2:before {
  content: "\e90f";
}
.icon-loop:before {
  content: "\e90d";
}
.icon-sentiment_satisfied_alt:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e906";
}
.icon-link1:before {
  content: "\e90e";
}
.icon-sd_storage:before {
  content: "\e90b";
}
.icon-format_line_spacing:before {
  content: "\e909";
}
.icon-format_list_numbered_rtl:before {
  content: "\e90a";
}
.icon-person:before {
  content: "\e907";
}
.icon-person_outline:before {
  content: "\e908";
}
.icon-sentiment_dissatisfied:before {
  content: "\e902";
}
.icon-sentiment_satisfied:before {
  content: "\e903";
}
.icon-sentiment_very_dissatisfied:before {
  content: "\e904";
}
.icon-sentiment_very_satisfied:before {
  content: "\e905";
}
.icon-check_box:before {
  content: "\e910";
}
.icon-check_box_outline_blank:before {
  content: "\e911";
}
.icon-logout:before {
  content: "\e90c";
}
.icon-filter_alt:before {
  content: "\e912";
}
.icon-comment-plus-outline:before {
  content: "\f0183";
}
.icon-tab-plus:before {
  content: "\f075c";
}
.icon-text-box-plus-outline:before {
  content: "\f0eab";
}
.icon-sticker-plus-outline:before {
  content: "\f136d";
}
.icon-pluscircleo:before {
  content: "\e625";
}
.icon-arrow-left:before {
  content: "\f004d";
}
.icon-arrow-right:before {
  content: "\f0054";
}
.icon-close:before {
  content: "\f0156";
}
.icon-keyboard-backspace:before {
  content: "\f030d";
}
.icon-menu:before {
  content: "\f035c";
}
.icon-replay:before {
  content: "\f0459";
}
.icon-cog:before {
  content: "\f0493";
}
.icon-caretdown:before {
  content: "\e606";
}
.icon-minuscircleo:before {
  content: "\e623";
}
.icon-infocirlceo:before {
  content: "\e629";
}
.icon-customerservice:before {
  content: "\e634";
}
.icon-questioncircleo:before {
  content: "\e63c";
}
.icon-mail:before {
  content: "\e659";
}
.icon-star:before {
  content: "\e660";
}
.icon-staro:before {
  content: "\e661";
}
.icon-shoppingcart:before {
  content: "\e668";
}
.icon-sharealt:before {
  content: "\e671";
}
.icon-setting:before {
  content: "\e672";
}
.icon-heart:before {
  content: "\e6a3";
}
.icon-hearto:before {
  content: "\e6a4";
}
.icon-earth:before {
  content: "\e6f1";
}
.icon-backspace:before {
  content: "\f006e";
}
.icon-bookmark-plus:before {
  content: "\f00c5";
}
.icon-tag-multiple:before {
  content: "\f04fb";
}
.icon-account-plus-outline:before {
  content: "\f0801";
}
.icon-doctor:before {
  content: "\f0a42";
}
.icon-backspace-outline:before {
  content: "\f0b5c";
}
.icon-backspace-reverse:before {
  content: "\f0e7e";
}
.icon-backspace-reverse-outline:before {
  content: "\f0e7f";
}
.icon-edit:before {
  content: "\f12c4";
}
.icon-tag-multiple-outline:before {
  content: "\f12f7";
}
.icon-check:before {
  content: "\e632";
}
.icon-close1:before {
  content: "\e633";
}
.icon-areachart:before {
  content: "\e65c";
}
.icon-linechart:before {
  content: "\e65d";
}
.icon-sharealt1:before {
  content: "\e673";
}
.icon-picture:before {
  content: "\e674";
}
.icon-cloudo:before {
  content: "\e67f";
}
.icon-cloud:before {
  content: "\e680";
}
.icon-cloudupload:before {
  content: "\e681";
}
.icon-clouddownload:before {
  content: "\e682";
}
.icon-clouddownloado:before {
  content: "\e683";
}
.icon-clouduploado:before {
  content: "\e684";
}
.icon-camerao:before {
  content: "\e68a";
}
.icon-export:before {
  content: "\e691";
}
.icon-delete:before {
  content: "\e69f";
}
.icon-upload:before {
  content: "\e6b6";
}
.icon-download:before {
  content: "\e6b7";
}
.icon-lock1:before {
  content: "\e6b9";
}
.icon-unlock:before {
  content: "\e6ba";
}
.icon-search1:before {
  content: "\e670";
}
.icon-filetext:before {
  content: "\e698";
}
.icon-edit1:before {
  content: "\e692";
}
.icon-form:before {
  content: "\e996";
}
.icon-attachment:before {
  content: "\e900";
}
.icon-database:before {
  content: "\e964";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-sort-numeric-asc:before {
  content: "\ea4a";
}
.icon-sort-numberic-desc:before {
  content: "\ea4b";
}
.icon-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
