.recordEditorContainer .recordEditorRow {
  .recordEditorBloodGlucose {
    min-width: 60%;
    width: 60%;
  }
  .recordEditorBloodGlucoseUnit {
    min-width: 36%;
    width: 36%;
  }
}
