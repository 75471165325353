@import "../../styles/colors.scss";
@import "../../styles/display.scss";
@import "../../styles/sizes.scss";

$recordAddTypeHeight: 35px;
$recordAddTypeCount: 13;

.recordPageContainer {
  @include flexView(column, flex-start, center);

  height: 100%;
  position: relative;
  width: 100%;

  &.recordPageCovered {
    .recordsContainer {
      overflow: hidden;
    }
  }

  .recordsContainer {
    @include flexView(column, flex-start, center);
    // background-image: $recordPageBackgroundGradient;
    height: calc(100% - $pageAddContainerHeight);
    overflow: auto;
    padding: 10px 0px;
    width: 100%;
  }

  .recordConnector {
    height: 5px;
    width: 5px;
  }

  .editRecordModal {
    max-height: 90%;
    width: clamp(350px, 90%, 500px);
  }

  .recordAddContainer {
    @include flexCenterView(column-reverse);
    background-color: $addButtonContainerBGColor;
    bottom: 0px;
    height: $pageAddContainerHeight;
    position: fixed;
    width: 90%;
    z-index: 2001;

    .recordAddButtonContainer {
      @include flexCenterView(column-reverse);
      align-self: center;
      border-radius: 3px;
      font-size: 20px;
      left: $pageAddButtonLeft;
      min-width: $pageAddButtonWidth;
      width: $pageAddButtonWidth;
      z-index: 2;

      .recordAddButton {
        background-color: $addButtonBGColor;
        box-shadow: 0px 1px 3px $addButtonBGColor;
        font-size: 14px;
        height: $pageAddButtonHeight;
        width: 100%;

        &.recordAddTypesView {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }

      .recordAddTypeSelect {
        @include flexCenterView(column);
        background-color: $addTypeSelectBGColor;
        border: 1px solid $addTypeSelectBorderColor;
        bottom: calc($pageAddContainerHeight / 2 + $pageAddButtonHeight / 2);
        box-shadow: 0 2px 8px $addTypeSelectShadowColor,
          0 0 2px $addTypeSelectShadowColor;
        font-size: 14px;
        height: 0px;
        overflow: hidden;
        position: absolute;
        transition: visibility 0.5s, z-index 0.5s, height 0.5s ease-in-out;
        visibility: hidden;
        width: $pageAddButtonWidth;
        z-index: 2;

        &.recordAddTypesView {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: calc($recordAddTypeHeight * $recordAddTypeCount);
          visibility: visible;
          z-index: 3;
        }

        .recordAddType {
          @include flexView(row, flex-start, center);
          border-bottom: 1px solid $addTypeItemBorderColor;
          cursor: pointer;
          height: $recordAddTypeHeight;
          padding: 5px 30%;
          text-align: center;
          width: 100%;

          &:hover {
            background-color: $addTypeItemHoverBGColor; //#e3e3e3;
          }

          img {
            height: 15px;
            margin-inline-end: 5px;
          }
        }
      }
    }
  }
}

.appContainerSmall {
  .recordsContainer,
  .recordAddContainer {
    margin-left: $menuWidthCompact;
  }

  .recordsContainer {
    width: calc(100% - $menuWidthCompact);
  }
}
