.recordEditorContainer .recordEditorRow {
  .recordEditorLabTestResultName {
    min-width: 30%;
    width: 30%;
  }

  .recordEditorLabTestResultResult {
    min-width: 35%;
    width: 35%;
  }

  .recordEditorLabTestResultUnit {
    min-width: 20%;
    width: 20%;
  }

  .recordEditorLabTestPlus,
  .recordEditorLabTestMinus {
    font-size: 25px;
    min-width: 10%;
    width: 10%;
  }
}
