@import "styles/colors.scss";

@font-face {
  font-family: "Loftygoals";
  src: local("Loftygoals"),
    url("./fonts/LoftygoalsRegular.otf") format("opentype");
}

body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  /* "Roboto", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

* {
  scrollbar-color: $scrollBarColor;
  scrollbar-width: thin;
}
