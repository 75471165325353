.recordEditorRow.recordEditorAssetRow {
  .recordEditorAssetText {
    min-width: 90%;
    width: 90%;
  }

  .recordEditorAssetDelete {
    cursor: pointer;
    font-size: 20px;
    min-width: 8%;
    width: 8%;
  }
}
