@import "../../styles/animations.scss";
@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.loadingModal {
  @include flexView(column !important, center !important, center !important);
  background-color: transparent !important;
  border-radius: 50% !important;
  border-width: 0px !important;
  height: 130px;
  padding: 0px !important;
  width: 130px;

  > div {
    @include flexView(column !important, center !important, center !important);
    height: 90% !important;
    overflow: hidden !important;
    padding: 0px !important;
    position: relative !important;
    width: 90% !important;

    .loadingRotatorBorder {
      @include flexCenterView(column);
      @include fullRotater(2s, infinite);
      background: $loadingBorderGradient;
      border-radius: 50%;
      height: 90%;
      position: absolute;
      width: 90%;
      z-index: 5001;

      .loadingRotator {
        background-color: $loadingBGColor;
        border-radius: 50%;
        height: 88%;
        width: 88%;
      }
    }
  }

  .loadingImage {
    height: 40px;
    z-index: 5002;
  }
}
