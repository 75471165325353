@import "../../styles/colors.scss";
@import "../../styles/display.scss";
@import "../../styles/sizes.scss";

.accountPageContainer {
    @include flexView(column, flex-start, center);
  
    height: 100%;
    position: relative;
    width: 100%;
  
    &.accountPageCovered {
      .recordWidgetsContainer {
        overflow: hidden;
      }
    }
  
    .accountWidgetsContainer {
        @include flexView(column, flex-start, flex-start);
        // background-image: $recordPageBackgroundGradient;
        height: calc(100% - $pageAddContainerHeight);
        overflow: auto;
        padding: 10px 20px ;
        width: 100%;
      }
    .accountWidgetItem{
        @include flexView(column, center, flex-start);
 
        position: relative;
        width: 90%;
       // background-color: #ffffff;
         border-bottom: 1px solid #DDD;
        // border-radius: 10px;
        min-height: 120px;
        padding: 10px 10px;
        margin: 10px 10px;
    }
    .smallWidget{
      .infoRow{
        flex-direction: column;
        .infoHeader{
         
          padding-right: 0px;
        }
      }
    }
    .noHeaderWidget{

      .infoRow{
       
        .infoHeader{
          display:none;

        }
      }
      
    }
}
.appContainerSmall {
    .accountWidgetsContainer{
      margin-left: $menuWidthCompact;
      width: calc(100% - $menuWidthCompact);
    }
  

  }
  .userProfileAvatar {
    background-color: $avatarBGColor;
    border: 1px solid $avatarBorderColor;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .infoRow{
    @include flexView(row, flex-start, center);
    flex:4;
    width: 100%;
    font-size: 14px;
    .infoHeader{
      display: flex;

      font-weight: 600;
      justify-content: flex-end;
      padding-right: 20px;
      flex:1;
      
    }
    .infoData{
      flex:3;
      font-weight: 300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .infoDataCaution{
      flex:3;
      font-weight: 500; 
      text-align: center;
      padding:10px;
    }
    .deleteHeader{
      font-weight: 700;
      color: red;

    }
    .deleteLink{
      flex:3;
      font-weight: 500;
      color: red;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .logoutButton{

    }

    
  }
  