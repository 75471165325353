.recordEditorContainer .recordEditorRow {
  .recordEditorBloodPressureD,
  .recordEditorBloodPressureS,
  .recordEditorHeartPulse,
  .recordEditorSpO2 {
    min-width: 48%;
    width: 48%;
  }

  .recordEditorBloodTemp {
    min-width: 60%;
    width: 60%;
  }
  .recordEditorBloodTempUnit {
    min-width: 36%;
    width: 36%;
  }
}
