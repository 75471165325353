//---------------------- ROTATION ---------------------//
@mixin rotater($duration, $count) {
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-name: rotation;
  animation-timing-function: linear;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-45deg);
  }
  70% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

//---------------------- FULL-ROTATION ---------------------//
@mixin fullRotater($duration, $count) {
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-name: fullRotation;
  animation-timing-function: linear;
}
@keyframes fullRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

//---------------------- SHAKE-ROTATION ---------------------//

//css-variable: --rotation-degree-start
//css-variable: --rotation-degree-end
@mixin shakeRotater($duration, $count) {
  --rotation-degree-start: 20deg;
  --rotation-degree-end: -20deg;
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-name: shakeRotation;
  animation-timing-function: linear;
}
@keyframes shakeRotation {
  20% {
    transform: rotate(var(--rotation-degree-start));
  }
  50% {
    transform: rotate(var(--rotation-degree-end));
  }
  80% {
    transform: rotate(0deg);
  }
}

//---------------------- SHAKE ---------------------//

//css-variable: --shake-size
@mixin shaker($duration, $count, $delay, $direction: x) {
  --shake-size: 2px;
  @if ($direction == x) {
    animation-name: shakerX;
  } @else {
    animation-name: shakerY;
  }
  animation-delay: $delay;
  animation-duration: $duration;
  animation-iteration-count: $count;
}

@keyframes shakerX {
  10% {
    transform: translateX(var(--shake-size));
  }
  20% {
    transform: translateX(calc(0px - var(--shake-size)));
  }
  30% {
    transform: translateX(var(--shake-size));
  }
  40% {
    transform: translateX(0px);
  }
}

@keyframes shakerY {
  10% {
    transform: translateY(var(--shake-size));
  }
  20% {
    transform: translateY(calc(0px - var(--shake-size)));
  }
  30% {
    transform: translateY(var(--shake-size));
  }
  40% {
    transform: translateY(0px);
  }
}

//---------------------- TRANSITION ---------------------//
@mixin transitionBox($prop) {
  overflow: hidden;
  -webkit-transition: $prop 0.5s ease-in-out;
  -moz-transition: $prop 0.5s ease-in-out;
  -o-transition: $prop 0.5s ease-in-out;
  transition: $prop 0.5s ease-in-out;
}
