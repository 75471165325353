@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.mpModalContainer {
  @include flexCenterView();
  background-color: $modalCoverBGColor;
  cursor: pointer;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 4001;

  &.mpModalContainerVisible {
    display: flex;
  }

  .mpModal {
    @include flexView(column, flex-start, center);
    background-color: $modalBGColor;
    border: 4px solid $modalBorderColor;
    border-radius: 10px;
    cursor: auto;
    max-height: 100%;

    .mpModalTitle {
      @include flexCenterView(row);
      background-color: $modalTitleBGColor;
      border-bottom: 1px solid $modalBorderColor;
      color: $modalTitleTextColor;
      cursor: unset;
      font-weight: 700;
      padding: 10px 10px 10px 0px;
      padding-bottom: 10px;
      position: relative;
      width: 100%;

      .mpModalClose {
        @include flexCenterView(row);
        cursor: pointer;
        font-size: 22px;
        position: absolute;
        right: 10px;
      }
    }

    .mpModalContent {
      @include flexView(column, flex-start, center);
      height: 95%;
      overflow: auto;

      padding: 0px 10px 10px 10px;
      padding: 10px 0px;
      width: 100%;
    }
  }
}
