@import "../../styles/colors.scss";
@import "../../styles/display.scss";



.loginPageContainer {
  @include flexView(column, flex-start, center);
  background: $loginBackgroundGradient;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  
  .socialLoginContainer {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Ensure children fill the container */
    width: 100%; /* Ensure the container spans the full width */

  }

  .loginPageBackground {
    display: none;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .loginLogo {
    @include flexCenterView(column);
    margin: 50px;

    img {
      height: 40px;
      margin-bottom: 5px;
    }
  
    span {
      color: $loginLogoTextColor;
      font-family: Loftygoals;
      font-size: 28px;
      white-space: nowrap;
    }
  }
  .splitter {
    display: flex;
    flex-direction: row;
    // overflow: hidden;
    align-items: center;
    text-align: center;
    margin: 12px 0 0;
    color: rgb(0,0,0,0.5)
  }
  .splitter::before,.splitter::after{
    content: "";
    background-color: rgb(0,0,0);
    opacity: .3;
    display: inline-block;
    height: 1px;
    margin:10px;
    position: relative;
    vertical-align: middle;
    
 
    width: 100px;
  }

  .loginContainer {
    @include flexView(column, space-between, center);
    // height: 80%;
    padding: 20px 20px;
    margin-bottom: 20px;
    // width: 500px;
    border: 1px solid hsl(var(--white));
    box-shadow: 0px 20px 80px hsla(var(--baseColor2), 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    background-image: radial-gradient(circle at 50% 0, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2) 20%);
    border-radius: 25px;
    
    z-index: 2;

    .registerContainer {
      margin-top: clamp(5px,0.8vw,15px);
      // padding: 0 30px 23px 30px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      align-items: center;
      width: 100%;
    }

    .inputDiv {
      display: flex;
      flex-direction: column;
      border: 0;
      // border-radius: 15px;
      width: 100%;
      // background-color: white;
      font-size: clamp(14px,2vw,16px);
      font-family: sans-serif;
      // padding: 19.5px;
      height: 60px;
      max-height: 60px;
      // margin-bottom: 10px;
    }

    .inputField {
      border-radius: 15px;
      width: 100%;
      background-color: white;
      
    }

    .loginGoogleButtonContainer {
      @include flexCenterView(column);
      // height: 80%;
      margin: 10px;
      padding-inline: 10px;
      justify-content: space-between;
      
      


      
    }
    .loginAppleButton{
      
    }
    .loginGoogleButton{

    }
    .loginAppleButtonContainer {
      @include flexCenterView(column);
      // height: 80%;
      margin: 10px;
      padding-inline: 10px;
      justify-content: space-between;
      
      


      
    }
  }
  .loginButtonContainer {
    margin-top: 30px;
   
  }
  .loginButton {
    text-transform: none;
  }
  .loginErrorMessage {
    color: red;
    text-shadow: 2px 2px 15px white;

    font-size: 14px;
  }
  .loginNormalMessage {
    
    font-size: 14px;
    text-shadow: 2px 2px 25px white;
    font-weight: bold;
  }

  .forgotPassword {
    font-size: 14px;
    align-self: flex-end;
  }
}

.footer {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.footerText {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.downloadLinks {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.downloadBadge {
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.downloadBadge:hover {
  transform: scale(1.05);
}
