@import "../../styles/display.scss";

.recordFieldInfoDiag,
.recordFieldInfoPrescription {
  @include flexView(row, flex-start, flex-start);

  flex-wrap: wrap;
  width: 100%;

  .recordFieldInfoValue {
    padding-inline-start: 5px;
  }
}
