$mediumGray: #555555;
$mediumRed: #ff0000;
$lightGray: #dae2eb;
$whitish: #d4f3fc;
$white: #ffffff;
$disabledTransparent: #d2cfcfab;

$colorDarkGray: #404040;
$colorBrown: #aa2626;
$colorBlackish: #0e0b0b;
// $colorCharcoal: #2D4356;
// $colorDeepSpaceSparkle: #435B66;
// $color A76F6F
