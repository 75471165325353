@import "../../styles/colors.scss";
@import "../../styles/display.scss";

$personSelectorItemHeight: 42px;



  .personSelectorTitle {
    @include flexCenterView(row);
    height: 40px;

    .personSelectorItem {
      background-color: unset;
      color: $headerTextColor;
      padding: 0px;
    }
  }

  .personSelectorList {
    @include flexCenterView(column);
    background-color: $personSelectorBGColor;

    border: 1px solid $personSelectorBorderColor;
    border-radius: 5px;
    box-shadow: 0 2px 8px $personSelectorShadowColor,
      0 0 2px $personSelectorShadowColor;
    min-width: 125px;
    opacity: 1;
    position: fixed;
    // right: 5px;
    top: 40px;
    z-index: 1001;

    &.personeSelectorListHide {
      opacity: 0;
      visibility: hidden;
    }

    div:last-child {
      border-bottom-width: 0px;
    }
  }

  .personSelectorItem {
    @include flexView(row, flex-start, center);
    border-bottom: 1px solid $personSelectorItemBorderColor;
    color: $personSelectorItemTextColor;
    cursor: pointer;
    min-width: 100%;
    padding: 10px;
    width: max-content;
 
    &.personSelectorSelectedItem {
      border-width: 0px;
      height: 25px;
      padding-inline-end: 0;
      // width: 25px;

      &:hover {
        background-color: unset;

        img {
          height: 100%;
          width: 100%;
        }
      }
      img {
        border: 1px solid $personSelectorSelectedBorderColor;
        height: 99%;
        width: 99%;
      }

      .defaultAvatar {
        height: 25px;
        width: 25px;
      }
    }

    &:hover {
      background-color: $personSelectorHoverBGColor;
    }

    &.personSelectorItemSelected {
      background-color: $personSelectorHoverBGColor;

      span {
        font-weight: 600;
      }
    }
    .personSelectorItemText{
      padding-left: 5px;
    }
    img {
      background-color: $personSelectorImageBGColor;
      border: 1px solid $personSelectorBorderColor;
      border-radius: 50%;
      height: 20px;
      padding: 1px;
      width: 20px;
    }

    .defaultAvatar {
      font-size: 16px;
      margin-inline-end: 5px;
    }

    span {
      font-size: 12px;
    }
  }

  .personSelectorToggler {
    cursor: pointer;
    flex-shrink: 0;
  }

