@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.userProfileMainContainer {
  @include flexCenterView(column);
  padding: 10px;

  width: 100%;
  .userProfileContainer{
    @include flexCenterView(column);
    padding: 10px;

    width: 100%;
    &:hover {
      color: $menuTextHoverColor;
        background-color: #DDD6;
        border-radius: 5px;
        font-weight: 600;
    }
  }
  
  .userProfileAvatarContainer {
    @include flexCenterView(column);
    cursor: pointer;
    height: auto;
    width: 70px;
    transition: width 0.5s;
    &.userProfileAvatarContainerCompact{
      width:$menuWidthCompact;
      // width:$menuWidthCompact;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // overflow: hidden;
      padding:5px;
    }

    .userProfileAvatar {
      background-color: $avatarBGColor;
      border: 1px solid $avatarBorderColor;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      &.userProfileAvatarCompact {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .userProfileTitleContainer {
    // @include flexCenterView(column);
    width:150px;
    cursor: pointer;
    .userProfileTitle {
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .userProfileEmail {
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .userProfileLogoutContainer {
    color: $menuTextColor;
    cursor: pointer;
    // font-weight: 600;
    padding-top: 10px;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    &:hover {
      // color: $userLogoutHoverTextColor;
      background-color: #DDD6;
      font-weight: 600;
    }

    .userProfileLogoutIcon {
      margin-bottom: 2px;
      margin-inline-end: 5px;
    }
  }
}
