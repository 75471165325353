@import "../../styles/_colors.scss";
@import "../../styles/_display.scss";

.pageFooter {
  @include flexView(row, center, center);

  background-color: $headerBGColor;
  border-top: 1px solid $headerBorderColor;
  color: $headerTextColor;
  font-size: 12px;
  height: $footerHeight;
  // padding-inline-end: 10px;
  position: relative;
  width: 100%; //$;

}
