@import "../../styles/colors.scss";
@import "../../styles/display.scss";
@import "../../styles/sizes.scss";

.container {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0; // Example background, adapt as needed
}

.header {
    text-align: center;
    padding: 20px;
    font-size: 24px;
}

.content {
    @include flexCenterView(column);
    flex:1;
    padding: 50px;
    margin-left: 20px;
}

.feedback-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.mood-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    

    .mood-button {
        margin: 0 5px;
        cursor: pointer;
        font-size: 40px;
        color:#AAA;
        // width:64px;
        // Add styles for your mood buttons here
        // Consider replacing placeholders with actual icons
    }

    .selected {
        margin: 0 5px;
        cursor: pointer;
        font-size: 40px;
        color:#007bff;
    }
}

.info-group {
    margin-bottom: 20px;
    width: 100%;
    label {
        display: block;
        margin-bottom: 5px;
    }

    input,
    textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px; // For spacing between fields
    }
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;

    &.disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}
