@import "../../styles/_colors.scss";
@import "../../styles/_display.scss";

.pageGeneralContainer {
  @include flexView(column, flex-start, flex-start);
  background-color: $pageBGColor;
  height: 100%;
  width: 100%;

  .pageContentContainer {
    @include flexView(row, flex-start, flex-start);
    flex-grow: 1;
    height: calc(100vh - $headerHeight - $footerHeight);
    // margin-top: $headerHeight;
    position: relative;
    width: 100%;
  }
  .pageRightContainer{
    @include flexView(column, flex-start, flex-start);
    flex:1;
  }

  .emptyPageContainer {
    @include flexCenterView(column);
    height: 100%;
    width: 100%;

    .emptyPageMessageContainer {
      @include flexCenterView(row);
      background-color: $emptyMessageHoverBGColor;
      border: 1px solid $emptyMessageBorderColor;
      border-radius: 20px;
      color: $emptyMessageTextColor;
      height: 200px;
      width: 90%;
    }
  }
}

#portal-modal-id {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
