.recordEditorContainer .recordEditorRow {
  .recordEditorSymptom {
    min-width: 90%;
    width: 90%;
  }
  .recordEditorSymptomPlus,
  .recordEditorSymptomMinus {
    font-size: 25px;
    min-width: 10%;
    width: 10%;
  }
}
