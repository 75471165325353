@import "./styles/display.scss";

.appContainer {
  height: 100vh;
  width: 100vw;
}

.MuiSelect-select,
.MuiSelect-nativeInput,
.MuiSelect-option,
.MuiMenuItem-root,
.MuiInputLabel-root {
  font-size: $selectInputFontSize !important;
}
