@import "./palette";

$themeDarkColor: #208f85;
$themeSideColor: #2b44e8;

//============= Add-Item ==================//
$addButtonContainerBGColor: white;
$addButtonBGColor: $themeDarkColor;
$addTypeSelectBGColor: #fff;
$addTypeSelectBorderColor: #bfbfc3;
$addTypeSelectShadowColor: rgba(31, 30, 36, 0.16);
$addTypeItemBorderColor: #f2f2f2;
$addTypeItemHoverBGColor: #f4f5fa;

//============= User-Profile ==================//
$avatarBorderColor: white;
$avatarBGColor: white;
$avatarIconColor: #555555;
$userLogoutTextColor: lightgray;
$userLogoutHoverTextColor: white;

//============= Header ==================//
$headerBGColor: $white;
$headerBorderColor: $themeSideColor;
$headerTextColor: $themeSideColor;

//============= Search-Bar ==================//
$headerSearchIconColor: $themeSideColor;
$headerSearchInputBorderColor: $themeSideColor;

//============= Person-Selector ==================//
$personSelectorBorderColor: #bfbfc3;
$personSelectorShadowColor: rgba(31, 30, 36, 0.16);
$personSelectorItemBorderColor: #f2f5f7;
$personSelectorItemTextColor: #555555;
$personSelectorSelectedBorderColor: #3366cc;
$personSelectorHoverBGColor: #f4f5fa;
$personSelectorImageBGColor: white;
$personSelectorImageBorderColor: #ccc;
$personSelectorBGColor: #fff;

//============= Menu ==================//
$menuBGColor: #fff;
$menuTextColor: #222;
$menuTextHoverColor: #222;
$menuBGHoverColor:#DDD;
$menuBorderColor: #ffffff;

//============= Menu-Drawer ==================//
$menuDrawerButtonColor: #3366cc;

//============= Menu-Profile ==================//
$menuProfileBGColor: #208f85;

$recordInfoFieldBGColor: white;
$recordInfoFieldBorderColor: white;

$editorButtonBGColor: #343957;
$recordEditorTagBGColor: #d0e3f1;
$recordEditorTagBorderColor: #d0e3f1;
$recordEditorTagTextColor: #39739d;
$recordEditorUploadBGColor: $themeDarkColor;

//============= Menu-Drawer ==================//
$modalBGColor: $white;
$modalBorderColor: $themeDarkColor;
$modalCoverBGColor: $disabledTransparent;
$modalTitleBGColor: $themeDarkColor;
$modalTitleTextColor: white;

$pageBGColor: $white;

$recordInfoIconColor: $mediumRed;

//============= Record-Item ==================//
$recordItemBorderColor: $themeSideColor;
$recordItemBGColor: $white;
$recordItemHoverBGColor: #f4f5fa;
$recordItemTitleTextColor: #000000e5;
$recordItemDateBGColor: white;
$recordItemDateBorderColor: $themeSideColor;
$recordItemDateTextColor: #343957;
$recordItemOperationRemoveTextColor: #f10000;
$recordItemOperationEditTextColor: #20b2aa;
$recordItemTagBGColor: #d0e3f1;
$recordItemTagBorderColor: #d0e3f1;
$recordItemTagTextColor: #39739d;

$recordPageBorderColor: $lightGray;
$recordPageBackgroundGradient: linear-gradient(to bottom, #f7f7f7, white);

$scrollBarColor: #888787 white;

//============= Login-Logo ==================//
$loginBackgroundGradient: linear-gradient(to bottom right, #185D59, #81FBFF);
$loginLogoTextColor: #0d40a3;
$loginLogoBGColor: white;
$loginLogoShadowColor: #5e7cb1;
$loginLogoBorderColor: #5e7cb1;
$loginErrorTextColor: #f10000;

//============= Person-Item ==================//
$personItemHoverBGColor: #f4f5fa;
$personItemBorderColor: #888787;
$personItemBGColor: white;
$personItemTextColor: #555555;

$editorButtonBGColor: $themeDarkColor;

$emptyMessageBorderColor: $recordItemBorderColor;
$emptyMessageHoverBGColor: $recordItemHoverBGColor;
$emptyMessageTextColor: #0d40a3;

//============= Google Login ==================//
$googleLoginShadowColor: #0040ac;
$googleLoginBGColor: #005fff;
$googleLoginInactiveShadowColor: #555555;
$googleLoginInactiveBGColor: #9f9f9f;
$googleLoginIconBGColor: white;

//============= 3D-Button ==================//
$button3DShadowColor: hsl(340deg 100% 32%);
$button3DBGColor: hsl(345deg 100% 47%);
$button3DTextColor: white;

//============= Loading ==================//
$loadingBorderGradient: linear-gradient(
  90deg,
  #3ec4b79c,
  #92f2e99c,
  #3ec4b79c,
  #92f2e99c
);
$loadingBGColor: #fff;
