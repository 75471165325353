.recordEditorContainer .recordEditorRow {
  .recordEditorHeight,
  .recordEditorWeight {
    min-width: 60%;
    width: 60%;
  }
  .recordEditorHeightUnit,
  .recordEditorWeightUnit {
    min-width: 36%;
    width: 36%;
  }
}
