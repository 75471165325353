@import "../../styles/animations.scss";
@import "../../styles/colors.scss";
@import "../../styles/display.scss";

div {
  .menuDrawerButton {
    @include flexView(column, center, flex-end);
    cursor: pointer;
    margin-left: 10px;

    > div {
      background-color: $menuDrawerButtonColor;
      height: 2px;
      margin-top: 4px;
      transition: rotate ease-in-out 1s, width ease-in-out 0.5s;
      width: 15px;
    }

    :first-child,
    :last-child {
      width: 10px;
    }

    &:hover {
      :first-child,
      :last-child {
        width: 15px;
      }
    }

    &.menuDrawerButtonCompact {
      :first-child,
      :last-child {
        transition: rotate ease-in-out 0.5s, margin-top ease-in-out 0.5s;
      }
      &:hover {
        div {
          margin-top: 0px;
        }

        :first-child,
        :last-child {
          transition: rotate ease-in-out 0.5s, margin-top ease-in-out 0.5s;
          width: 10px;
        }
        :first-child {
          rotate: 30deg;
        }

        :last-child {
          rotate: -30deg;
        }
      }
    }
  }
}
