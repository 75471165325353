@import "../../styles/animations.scss";
@import "../../styles/display.scss";

.syncer__container {
  @include flexCenterView(row);
  cursor: pointer;
  margin-inline-end: 10px;
  margin-top: 4px;

  &:hover {
    @include rotater(1s, 1);
  }

  .syncer__icon {
    height: 20px;
    width: 20px;
  }
}
