@import "../../styles/colors.scss";
@import "../../styles/display.scss";

.recordItemTag {
  @include flexCenterView(column);
  background-color: $recordEditorTagBorderColor;
  border: 1px solid $recordEditorTagBorderColor;
  border-radius: 4px;
  color: $recordItemTagTextColor;
  font-size: 12px;
  margin: 2px;
  padding: 2px 14px 2px 6px;
  position: relative;

  .recordItemTagRemove {
    color: $recordEditorTagTextColor;
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
  }
}
